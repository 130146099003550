import type { SVGProps } from 'react'
import React from 'react'

export type IconGenericType = (props: SVGProps<SVGSVGElement>) => JSX.Element

interface Props
  extends React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
  > {
  /** If true, Icon has hover styles */
  hover?: boolean
  /** If true, Icon renders with disabled styles */
  disabled?: boolean
  /** Use to rotate the Icon */
  rotate?: number
  /** ie: 15px, default is 24px */
  width?: string
  /** ie: 15px, default is undefined */
  height?: string
  /** if true, width and height will not be set via the style prop */
  noStyleDimensions?: boolean
}

const Icon: React.FC<Props> = ({
  alt,
  disabled,
  hover,
  rotate,
  width,
  height,
  className,
  noStyleDimensions,
  ...other
}) => {
  const iconWidth = width || '24px'
  const iconHeight = height || undefined
  let filter = undefined
  if (disabled) {
    filter = 'contrast(0%)'
  }

  return (
    <div
      style={
        !noStyleDimensions
          ? { width: iconWidth, height: iconHeight }
          : undefined
      }
      className={className}
    >
      <img
        alt={alt}
        className={
          '' +
          (hover ? ' cursor-pointer hover:opacity-80' : '') +
          (disabled ? ' cursor-not-allowed hover:opacity-100' : '')
        }
        style={{
          width: '100%',
          height: '100%',
          transform: `rotate(${rotate == undefined ? 0 : rotate}deg)`,
          filter: filter,
          opacity: disabled ? '0.5' : undefined,
        }}
        {...other}
      />
    </div>
  )
}

export default Icon
